// NadeActions.js
import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { URL } from '../helpers';

const NadeActions = ({ nade, user, onDelete }) => {
  // Only show edit/delete buttons if user is logged in and is the author
  if (!user || !nade || user._id !== nade.authorID) {
    return null;
  }

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this nade?')) {
      try {
        await axios.delete(`${URL}/deleteNade/${nade._id}`, {
          withCredentials: true
        });
        NotificationManager.success('Nade deleted successfully!', 'Success!', 4000);
        if (onDelete) onDelete();
      } catch (error) {
        NotificationManager.error('Error deleting nade', 'Error', 4000);
      }
    }
  };

  return (
    <div className="nade-actions mt-3">
      <Link 
        to={`/maps/${nade.map}/${nade._id}/edit`}
        className="btn btn-warning mr-2"
      >
        Edit
      </Link>
      <button 
        className="btn btn-danger" 
        onClick={handleDelete}
      >
        Delete
      </button>
    </div>
  );
};

export default NadeActions;