import React, { Component } from "react";
import MapDropdown from "./MapDropdown";
import MapOverlay from "./MapOverlay";
import DATA from "../data/mapData";
import RadioButtonsForType from "./RadioButtonsForType";
import RadioButtonsForSide from "./RadioButtonsForSide";
import RadioButtonsForLines from "./RadioButtonsForLines";
import TextInput from "./TextInput";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import { URL as API_URL } from "../helpers";
import { Link } from "react-router-dom";

class SubmitNade extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mapChoice: "Mirage",
      mapImage: "",
      mapAlt: "",
      nadeTitle: "",
      nadeURL: "",
      description: "",
      selectedOption: "Smoke",
      selectedSideOption: "T",
      startX: 0,
      startY: 0,
      midX: 0,
      midY: 0,
      endX: 0,
      endY: 0,
      starterFlag: true,
      midFlag: false,
      lines: "1",
      images: [],
      isEditing: false,
      nadeId: null
    };
  }

  async componentDidMount() {
    // Set initial map image
    DATA.forEach((mapObj) => {
      if (mapObj.mapTitle === this.state.mapChoice) {
        this.setState({ mapImage: mapObj.overlaysrc, mapAlt: mapObj.mapAlt });
      }
    });

    // If we're editing (has nadeId in props), load the nade data
    if (this.props.nadeId) {
      try {
        const response = await axios.get(`${API_URL}/loadNadeVideo`, {
          data: { nadeID: this.props.nadeId },
          withCredentials: true
        });
        
        const nade = response.data;
        this.setState({
          isEditing: true,
          nadeId: this.props.nadeId,
          mapChoice: nade.map.charAt(0).toUpperCase() + nade.map.slice(1),
          nadeTitle: nade.title,
          nadeURL: nade.url,
          description: nade.description,
          selectedOption: nade.type,
          selectedSideOption: nade.side,
          startX: nade.startX,
          startY: nade.startY,
          midX: nade.midX,
          midY: nade.midY,
          endX: nade.endX,
          endY: nade.endY,
          lines: nade.lines,
          images: nade.images ? nade.images.map(filename => ({
            file: null,
            preview: `/uploads/${filename}`,
            existing: true,
            filename: filename
          })) : []
        });

        // Update map image based on loaded nade's map
        DATA.forEach((mapObj) => {
          if (mapObj.mapTitle.toLowerCase() === nade.map.toLowerCase()) {
            this.setState({ mapImage: mapObj.overlaysrc, mapAlt: mapObj.mapAlt });
          }
        });
      } catch (error) {
        NotificationManager.error("Error loading nade data", "Error", 4000);
      }
    }
  }

  componentWillUnmount() {
    // Clean up object URLs to prevent memory leaks
    this.state.images.forEach(image => {
      if (image.preview && !image.existing) {
        URL.revokeObjectURL(image.preview);
      }
    });
  }

  handleMapChange = (event) => {
    this.setState({ mapChoice: event.target.value });
    DATA.forEach((mapObj) => {
      if (mapObj.mapTitle === event.target.value) {
        this.setState({ mapImage: mapObj.overlaysrc, mapAlt: mapObj.mapAlt });
      }
    });
  };

  handleFilesSelect = (event) => {
    const filesWithPreview = Array.from(event.target.files).map(file => ({
      file,
      preview: URL.createObjectURL(file),
      existing: false
    }));
    
    this.setState({
      images: [...this.state.images, ...filesWithPreview],
    });
  };

  handleImageRemove = (indexToRemove) => {
    const imageToRemove = this.state.images[indexToRemove];
    if (!imageToRemove.existing) {
      URL.revokeObjectURL(imageToRemove.preview);
    }
    this.setState({
      images: this.state.images.filter((_, index) => index !== indexToRemove)
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    
    const mapChoiceAdjusted = this.state.mapChoice === "Dust ll" ? "dust2" : this.state.mapChoice;
    const formData = new FormData();
    
    // Append new images to formData
    this.state.images.forEach((image) => {
      if (image.file) { // Only append new files
        formData.append('files', image.file);
      }
    });

    // Append existing image filenames
    const existingImages = this.state.images
      .filter(img => img.existing)
      .map(img => img.filename);
    formData.append('existingImages', JSON.stringify(existingImages));
  
    // Append other form fields
    formData.append("mapChoice", mapChoiceAdjusted.charAt(0).toLowerCase() + mapChoiceAdjusted.slice(1));
    formData.append("nadeTitle", this.state.nadeTitle);
    formData.append("description", this.state.description);
    formData.append("nadeURL", this.state.nadeURL);
    formData.append("selectedOption", this.state.selectedOption);
    formData.append("selectedSideOption", this.state.selectedSideOption);
    formData.append("startX", this.state.startX);
    formData.append("startY", this.state.startY);
    formData.append("midX", this.state.midX);
    formData.append("midY", this.state.midY);
    formData.append("endX", this.state.endX);
    formData.append("endY", this.state.endY);
    formData.append("lines", this.state.lines);
    formData.append("loggedIn", this.props.loggedIn);

    try {
      const url = this.state.isEditing 
        ? `${API_URL}/updateNade/${this.state.nadeId}`
        : `${API_URL}/submitNade`;

      const response = await axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        withCredentials: true,
      });

      // Reset form if creating new nade
      if (!this.state.isEditing) {
        this.setState({
          nadeTitle: "",
          nadeURL: "",
          description: "",
          images: [],
          startX: 0,
          startY: 0,
          midX: 0,
          midY: 0,
          endX: 0,
          endY: 0,
          starterFlag: true
        });
      }

      NotificationManager.success(
        this.state.isEditing ? "Nade updated successfully!" : "Pending approval!", 
        "Success!", 
        4000
      );

      // Redirect back to nade page if editing
      if (this.state.isEditing && this.props.onUpdate) {
        this.props.onUpdate();
      }
    } catch (error) {
      if (error.response && error.response.data.errors) {
        error.response.data.errors.forEach((err) => {
          NotificationManager.error(err.msg, "Error", 4000);
        });
      } else {
        NotificationManager.error(
          this.state.isEditing ? "Error updating nade" : "Error submitting nade", 
          "Error", 
          4000
        );
      }
    }
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  mouseClicker = (event) => {
    var root = document.getElementById("svgID");
    var uupos = root.createSVGPoint();
    uupos.x = event.clientX;
    uupos.y = event.clientY;
    var ctm = event.target.getScreenCTM();
    if ((ctm = ctm.inverse())) {
      uupos = uupos.matrixTransform(ctm);
    }
    let x = Math.floor(uupos.x);
    let y = Math.floor(uupos.y);
    if (this.state.starterFlag) {
      this.setState({
        startX: x,
        startY: y,
        starterFlag: false,
        midFlag: true
      });
    } else if (this.state.midFlag && this.state.lines === "2") {
      this.setState({ midX: x, midY: y, midFlag: false });
    } else {
      this.setState({ endX: x, endY: y, starterFlag: true });
    }
  };

  render() {
    return (
      <div className="container">
        <h2 className="mapTitle">
          {this.state.isEditing ? "Edit nade" : "Submit your own"}
        </h2>
        <form onSubmit={this.handleSubmit} noValidate>
          <div className="form-group">
            <MapDropdown
              handleMapChange={this.handleMapChange}
              mapChoice={this.state.mapChoice}
            />
          </div>
          
          <div className="form-group">
            <label className="label">Title: </label>
            <TextInput
              inputText={this.state.nadeTitle}
              name="nadeTitle"
              onChange={this.handleChange}
            />
          </div>
          
          <div className="form-group">
            <label className="label">Description: </label>
            <TextInput
              inputText={this.state.description}
              name="description"
              onChange={this.handleChange}
            />
          </div>
          
          <div className="form-group">
            <label className="label">Upload Images:</label>
            <input
              type="file"
              className="form-control-file"
              onChange={this.handleFilesSelect}
              accept="image/*"
              multiple
            />
            <div className="image-preview">
              {this.state.images.map((fileWithPreview, index) => (
                <div key={index} className="image-thumbnail">
                  <img 
                    src={fileWithPreview.preview} 
                    alt={`preview ${index}`} 
                    className="img-thumbnail"
                  />
                  <button 
                    type="button"
                    className="btn btn-danger btn-sm remove-image"
                    onClick={() => this.handleImageRemove(index)}
                  >
                    ×
                  </button>
                </div>
              ))}
            </div>
          </div>
          
          <div className="form-group">
            <label className="label">YouTube Embed URL:</label>
            <Link to="/instructions" className="ml-2">
              What's this?
            </Link>
            <TextInput
              inputText={this.state.nadeURL}
              name="nadeURL"
              onChange={this.handleChange}
              placeholder="https://www.youtube.com/embed/UCKJ5w81MjM"
            />
          </div>
          
          <div className="form-group">
            <RadioButtonsForType
              selectedOption={this.state.selectedOption}
              handleChange={this.handleChange}
            />
          </div>
          
          <div className="form-group">
            <RadioButtonsForSide
              selectedSideOption={this.state.selectedSideOption}
              handleChange={this.handleChange}
            />
          </div>
          
          <div className="form-group">
            <RadioButtonsForLines
              lines={this.state.lines}
              handleChange={this.handleChange}
            />
          </div>
          
          <div className="form-group">
            <button className="btn btn-primary" type="submit">
              {this.state.isEditing ? "Update Nade" : "Submit Nade"}
            </button>
            {this.state.isEditing && (
              <Link 
                to={`/maps/${this.state.mapChoice.toLowerCase()}/${this.state.nadeId}`}
                className="btn btn-secondary ml-2"
              >
                Cancel
              </Link>
            )}
          </div>
          
          <div className="form-group">
            <MapOverlay
              show={false}
              showUserNade={true}
              mapImage={this.state.mapImage}
              mapAlt={this.state.mapAlt}
              mouseClicker={this.mouseClicker}
              startX={this.state.startX}
              startY={this.state.startY}
              midX={this.state.midX}
              midY={this.state.midY}
              endX={this.state.endX}
              endY={this.state.endY}
              selectedOption={this.state.selectedOption}
              nadeClass={"userNadeNormal"}
              lines={this.state.lines}
              icon={"list"}
            />
          </div>
        </form>
      </div>
    );
  }
}

export default SubmitNade;