import React, { useState, useEffect } from 'react';

const ImageGallery = ({ images, baseUrl }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (images.length > 0 && !selectedImage) {
      setSelectedImage(images[0]);
    }
  }, [images]);

  const handleThumbnailClick = (image, index) => {
    setSelectedImage(image);
    setCurrentIndex(index);
  };

  const handleModalOpen = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const navigateImage = (direction) => {
    const newIndex = direction === 'next' 
      ? (currentIndex + 1) % images.length 
      : (currentIndex - 1 + images.length) % images.length;
    
    setCurrentIndex(newIndex);
    setSelectedImage(images[newIndex]);
  };

  const handleKeyPress = (e) => {
    // Check if any text input elements are focused
    const activeElement = document.activeElement;
    const isInputFocused = activeElement.tagName === 'INPUT' || 
                          activeElement.tagName === 'TEXTAREA' ||
                          activeElement.isContentEditable;
    
    // Only handle arrow keys if no input is focused
    if (!isInputFocused) {
      if (e.key === 'ArrowRight') {
        e.preventDefault(); // Prevent page scrolling
        navigateImage('next');
      }
      if (e.key === 'ArrowLeft') {
        e.preventDefault(); // Prevent page scrolling
        navigateImage('prev');
      }
      if (e.key === 'Escape' && showModal) {
        handleModalClose();
      }
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [showModal, currentIndex]); // Dependencies include what we use in handleKeyPress

  if (!images || images.length === 0) {
    return null;
  }

  return (
    <div className="gallery-container">
      {/* Main Image */}
      <div className="main-image-container">
        {selectedImage && (
          <>
            <button 
              className="nav-button prev main-view"
              onClick={(e) => {
                e.stopPropagation();
                navigateImage('prev');
              }}
            >
              ‹
            </button>
            <img
              src={`${baseUrl}/images/${selectedImage}`}
              alt="Main"
              className="main-image cursor-pointer"
              onClick={handleModalOpen}
            />
            <button 
              className="nav-button next main-view"
              onClick={(e) => {
                e.stopPropagation();
                navigateImage('next');
              }}
            >
              ›
            </button>
          </>
        )}
      </div>

      {/* Thumbnails */}
      <div className="thumbnails-container">
        <div className="thumbnails-scroll">
          {images.map((image, index) => (
            <img
              key={index}
              src={`${baseUrl}/images/${image}`}
              alt={`Thumbnail ${index + 1}`}
              className={`thumbnail-image ${selectedImage === image ? 'selected' : ''}`}
              onClick={() => handleThumbnailClick(image, index)}
            />
          ))}
        </div>
      </div>

      {/* Modal */}
      {showModal && (
        <div className="modal-backdrop" onClick={handleModalClose}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button 
              className="nav-button prev"
              onClick={(e) => {
                e.stopPropagation();
                navigateImage('prev');
              }}
            >
              ‹
            </button>
            <img
              src={`${baseUrl}/images/${selectedImage}`}
              alt="Selected"
              className="modal-image"
            />
            <button 
              className="nav-button next"
              onClick={(e) => {
                e.stopPropagation();
                navigateImage('next');
              }}
            >
              ›
            </button>
            <button 
              className="close-button"
              onClick={handleModalClose}
            >
              ×
            </button>
          </div>
        </div>
      )}

      <style>{`
        .gallery-container {
          width: 100%;
          margin: 0 auto;
        }

        .main-image-container {
          width: 100%;
          margin-bottom: 1rem;
          background: #000;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
        }

        .main-image {
          width: 100%;
          height: auto;
          max-height: 80vh;
          object-fit: contain;
          cursor: pointer;
          display: block;
        }

        .thumbnails-container {
          width: 100%;
          overflow: hidden;
          margin-bottom: 1rem;
          display: flex;
          justify-content: center;
        }

        .thumbnails-scroll {
          display: flex;
          gap: 0.5rem;
          overflow-x: auto;
          padding: 0.5rem 0;
          scrollbar-width: thin;
          scroll-behavior: smooth;
          justify-content: center;
          min-width: min-content;
          margin: 0 auto;
        }

        .thumbnail-image {
          width: 100px;
          height: 100px;
          object-fit: cover;
          cursor: pointer;
          border: 2px solid transparent;
          transition: all 0.2s ease;
        }

        .thumbnail-image.selected {
          border-color: #3b82f6;
        }

        .modal-backdrop {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.9);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
        }

        .modal-content {
          position: relative;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .modal-image {
          max-width: 100%;
          max-height: 100vh;
          object-fit: contain;
        }

        .nav-button {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          background: rgba(255, 255, 255, 0.1);
          border: none;
          color: white;
          font-size: 2rem;
          padding: 1rem;
          cursor: pointer;
          transition: background 0.2s;
          z-index: 1;
        }

        .nav-button.main-view {
          background: rgba(0, 0, 0, 0.3);
          opacity: 0;
          transition: opacity 0.2s;
        }

        .main-image-container:hover .nav-button.main-view {
          opacity: 1;
        }

        .nav-button:hover {
          background: rgba(255, 255, 255, 0.2);
        }

        .nav-button.main-view:hover {
          background: rgba(0, 0, 0, 0.5);
        }

        .prev {
          left: 1rem;
        }

        .next {
          right: 1rem;
        }

        .close-button {
          position: absolute;
          top: 1rem;
          right: 1rem;
          background: none;
          border: none;
          color: white;
          font-size: 2rem;
          cursor: pointer;
        }

        .thumbnails-scroll::-webkit-scrollbar {
          height: 6px;
        }

        .thumbnails-scroll::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        .thumbnails-scroll::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 3px;
        }

        .thumbnails-scroll::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
      `}</style>
    </div>
  );
};

export default ImageGallery;