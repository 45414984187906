// EditNade.js
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import { URL as API_URL } from '../helpers';
import SubmitNade from './SubmitNade';
import { NotificationManager } from 'react-notifications';

class EditNade extends Component {
  state = {
    loading: true,
    authorized: false,
    redirect: false
  };

  async componentDidMount() {
    if (!this.props.loggedIn) {
      NotificationManager.error('You must be logged in to edit nades', 'Error', 4000);
      this.setState({ redirect: true });
      return;
    }

    try {
      // Load the nade to check authorization
      const response = await axios.get(`${API_URL}/loadNadeVideo`, {
        data: { nadeID: this.props.match.params.nadeId },
        withCredentials: true
      });

      // Check if the current user is the author
      if (response.data.authorID === this.props.user._id) {
        this.setState({ authorized: true, loading: false });
      } else {
        NotificationManager.error('You can only edit your own nades', 'Error', 4000);
        this.setState({ redirect: true });
      }
    } catch (error) {
      NotificationManager.error('Error loading nade', 'Error', 4000);
      this.setState({ redirect: true });
    }
  }

  handleUpdate = () => {
    // Redirect back to the nade page after successful update
    this.setState({ redirect: true });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={`/maps/${this.props.match.params.mapId}/${this.props.match.params.nadeId}`} />;
    }

    if (this.state.loading) {
      return <div className="container">Loading...</div>;
    }

    if (!this.state.authorized) {
      return <Redirect to="/maps" />;
    }

    return (
      <SubmitNade
        nadeId={this.props.match.params.nadeId}
        loggedIn={this.props.loggedIn}
        onUpdate={this.handleUpdate}
      />
    );
  }
}

export default EditNade;